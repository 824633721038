#general {
  display: flex;
  flex-direction: column;
  align-items: center;
}
pre {
  width: 100%;
}

p {
  width: 100%;
}

@media screen and (min-width: 600px) {
  #infos {
    flex-direction: row;
  }
  #image {
    width: 65%;
  }
}
@media screen and (max-width: 600px) {
  #infos {
    flex-direction: column;
  }
  #image {
    width: 100%;
  }
}

#infos {
  height: 75vh;
  background-color: #ffa887;
  display: flex;
  padding-inline: 0;
  padding-bottom: 0;
}

#infos_general {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding-bottom: 5%;
}

#titre_infos {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: black;
}

.info p {
  margin-top: 0;
  margin-bottom: 0;
}

.info a:link {
  color: red;
}

#image {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  overflow: hidden;
}

#partner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#programme {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d4e5df;
  height: 90vh;
}

#programme iframe {
  width: 80%;
  height: 90%;
}

#actusBss {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1100px) {
  #actusBss #bssCards {
    flex-direction: row;
  }
}
@media screen and (max-width: 1100px) {
  #actusBss #bssCards {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

#actusBss #bssCards {
  display: flex;
  width: 100%;
  padding-inline: 0;
  justify-content: space-between;
}
