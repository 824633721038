.navLink {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  nav {
    height: 10vh;
    background-color: #eab95f;
    margin: 0;
  }

  nav img {
    width: auto;
    height: 10vh;
  }

  #navBarLinks.mobile-menu {
    margin-left: 0;
    transition: 1s;
    z-index: 1;
  }

  nav li {
    font-size: 28px;
    font-weight: 600;
  }

  nav ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  #menuIcon {
    aspect-ratio: 1/1;
    height: 5vh;
    position: absolute;
    z-index: 2;
    right: 5vw;
    top: 2.5vh;
  }

  #navBarLinks {
    display: flex;
    position: absolute;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.718);
    backdrop-filter: blur(15px);
    width: 100%;
    height: 50vh;
    top: 0;
    left: 0;
    justify-content: space-between;
    align-items: center;
    padding-top: 25vh;
    padding-bottom: 25vh;
    margin-left: -100%;
    list-style-type: none;
    transition: 1s;
  }

  #bssNavBar {
    display: none;
    height: auto;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  nav ul li:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  #bssNavBar li {
    width: 100%;
  }

  #bssLi:hover ul {
    display: flex;
    flex-direction: column;
    position: relative;
    /* background-color: #eab95f; */
    margin: 0;
    padding: 1vh;
  }
}

@media screen and (min-width: 800px) {
  #menuIcon {
    display: none;
  }
  nav {
    height: 10vh;
    background-color: #eab95f;
    padding-inline: 5vw;
    margin: 0;
  }

  nav img {
    width: auto;
    height: 10vh;
    padding-right: 5vw;
  }

  nav #navBarLinks {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  nav ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  nav li {
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: 600;
    height: 100%;
    width: 100%;
    justify-content: center;
  }

  #bssNavBar {
    display: none;
    height: auto;
    margin: 0;
    padding: 0;
  }

  nav ul li:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  #bssNavBar li {
    width: 100%;
  }

  #bssLi:hover ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10vh;
    height: 150px;
    background-color: #eab95f;
    width: 20vw;
    margin: 0;
    padding: 0;
  }
}
