.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 50px;
  background: linear-gradient(to bottom, #8fbcc4, #8fbcc575);
  overflow: hidden;
}

.card img {
  width: 90%;
  height: 50%;
  border-radius: 50px;
}

.card .text_fadeout {
  width: 96%;
  height: 30%;
  padding-bottom: 2%;
}

.card p {
  overflow: hidden;
  height: 100%;
  margin: 0;
  word-wrap: break-word;
}
