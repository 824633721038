footer {
  display: flex;
  height: fit-content;
  width: auto;
  flex-direction: column;
  background: linear-gradient(to bottom, #ede5c1, #ca9e4d);
  padding: 5%;
}

footer div,
footer p {
  margin: 0;
}
