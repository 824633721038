#presentation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 5%;
}

#presentation p {
  width: 100%;
}
