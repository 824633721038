@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Bitter", serif;
  font-size: 20px;
  background-color: #ede5c1;
}

pre {
  font-family: inherit;
  word-wrap: break-word;
  white-space: pre-wrap;
}

h2 {
  font-size: 1.5em;
}

section {
  padding-bottom: 5vh;
  padding-inline: 5%;
}

p {
  word-wrap: break-word;
}

.navLink {
  text-decoration: none;
  color: black;
}
