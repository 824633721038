#slider {
  height: 75vh;
  width: 100%;
  overflow: hidden;
}

.slides {
  width: 400%;
  height: 100%;
  display: flex;
}

.slide {
  display: flex;
  height: 100%;
  width: 100vw;
  transition: 2s;
  background-size: cover;
}

@media screen and (max-width: 600px) {
  .slide {
    align-items: end;
  }
  .slide .backgroundDiv {
    width: 100%;
    height: 65%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  }
  .description {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5%;
    padding-inline: 5%;
    width: 100%;
    height: 75%;
    flex-direction: column;
  }
}

@media screen and (min-width: 600px) {
  .slide .backgroundDiv {
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  }

  .description {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
    width: 75%;
    height: 100%;
    flex-direction: column;
  }
}

.slide div h2 {
  color: white;
}

.slide div p {
  color: white;
}

#btn_left {
  position: absolute;
  width: 3.5%;
  height: 75vh;
  background-color: rgba(0, 0, 0, 0.5);
  border: 0;
}

#btn_left img {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

#btn_right {
  position: absolute;
  width: 3.5%;
  height: 75vh;
  background-color: rgba(0, 0, 0, 0.5);
  right: 0;
  border: 0;
}

#btn_right img {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}
