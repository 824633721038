.article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-inline: 2.5vw;
}
.page {
  width: 100vw;
}
