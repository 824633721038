#actualitees {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1100px) {
  #actualitees #homeCards {
    display: flex;
    width: 90vw;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 1100px) {
  #actualitees #homeCards {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
