#actus {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* https://betterprogramming.pub/css-grid-in-react-f8323b521fab */

/* #cards {
  padding-inline: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
} */

#cards {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#actus_btn {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

#btnPrevious {
  margin-left: 5vw;
}

#btnNext {
  margin-right: 5vw;
}
