.horizontaCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1%;
  margin-bottom: 30px;
  border-radius: 50px;
  background: linear-gradient(to left, #8fbcc4, #8fbcc575);
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  .horizontaCard {
    height: 13vw;
  }
}

@media screen and (max-width: 800px) {
  .horizontaCard {
    height: 100px;
  }
}

.textAndButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.horizontaCard img {
  width: 30%;
  height: 100%;
  border-radius: 50px;
}

.horizontaCard .text_fadeout {
  height: 30%;
  padding-bottom: 2%;
}

.textAndButton h2 {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.textAndButton MyButton {
}

.textAndButton {
  width: 100%;
  height: 100%;
}

.textAndButton p {
  word-wrap: break-word;
  overflow: hidden;
  font-size: 15px;
  width: 85%;
  margin: 0;
}
